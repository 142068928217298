<!-- 制度中心 -->
<template>
  <div class="system-container">
    <div class="system-wrap">
      <p>{{title}} --- component: views/Home/System</p>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeMount, onMounted } from "vue";
export default {
  name: "System",
  setup() {
    const data = reactive({
      title: "This is System Page",
    });
    onBeforeMount(() => {});
    onMounted(() => {});
    return {
      ...toRefs(data),
    };
  },
};
</script>
<style scoped lang="scss">
.system-container {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  box-sizing: border-box;
  p {
    font-family: 'SourceLight'; 
    font-size: 30px;
    color: #3d8fff;
    text-align: center;
  }
}
</style>
